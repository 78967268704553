<script setup lang="ts">
import type { ISetContentIconProps } from '~/components/SetContentIcon/setContentIcon.types';

defineProps<ISetContentIconProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const iconsStyles = computed(() => ({
  width: changeXL(16, 12),
  height: changeXL(16, 12),
}));
</script>

<template>
  <div class="pack-content-icon-wrapper">
    <LazySvgoCarouselPackIcon v-if="isInCarousel" v-bind="iconsStyles" />
    <LazySvgoSetContentIcon v-else v-bind="iconsStyles" />
    <span class="pack-content-icon-wrapper__text">{{ count }}</span>
  </div>
</template>

<style scoped lang="scss" src="./SetContentIcon.scss" />
